import { useNavigate } from "react-router-dom";
import processing from "../../assets/Images/processing.svg";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeCurrentModuleName,
  setIsTestInstructionsOpen,
  setIsTestStarted,
  setOverallResultSlice,
  setQid,
} from "../../redux/Slices/startFullTestSlice";
import { selectFullTestState } from "../../redux/Selectors/selectorsUse";
import {
  useEndTestMutation,
  useSaveAndExitTestMutation,
  useScaledScoresMutation,
  useUserProcessActivityMutation,
} from "../../redux/API/StartTest/startTestApi";
import { getItem } from "../../utils/token";
import AuthButton from "../../pages/LogInPage/LoginButton";
import { RxHome } from "react-icons/rx";
import { generateScaledScore } from "../../utils";
const TestCompletetion = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { startTestSlice, resultSlice } = useSelector(selectFullTestState);
  const [scaledScores] = useScaledScoresMutation({});
  const [saveAndExitTest, { isSuccess: isSaveSuccess }] =
    useSaveAndExitTestMutation({});
  const [endTest] = useEndTestMutation();
  const [userProcessActivity] = useUserProcessActivityMutation();
  const {
    readingModuleOneQuestions,
    readingModuleTwoQuestions,
    mathModuleOneQuestions,
    mathModuleTwoQuestions,
    userTestId,
    testId,
    currentModuleName,
    count,
    currentTime,
    readingAndWritingModuleOneSpentTime,
    readingAndWritingModuleTwoSpentTime,
    mathModuleOneSpentTime,
    mathModuleTwoSpentTime,
  } = startTestSlice;
  const {
    writingModuleOneAnswers,
    writingModuleTwoAnswers,
    mathsModuleOneAnswers,
    mathsModuleTwoAnswers,
  } = resultSlice;

  const transformString = (input: any) => {
    if (typeof input === "string") {
      // Convert to lowercase
      let result = input?.toLowerCase();

      // Remove spaces
      result = result?.replace(/\s/g, "");

      // Remove commas
      result = result?.replace(/,/g, "");

      // Remove periods (dots)
      result = result?.replace(/\./g, "");

      return result;
    }
  };

  const caluculateOverallResult = (questions: any, answers: any) => {
    return questions.map((question: any) => {
      const matchedAnswer = answers.find(
        (answer: any) => answer.qId === question._id
      );

      if (!matchedAnswer || Object.keys(matchedAnswer).length === 0) {
        return {
          ...question,
          isAttempted: false,
          isCorrect: false,
          userAnswer: "",
          userOption: "",
        };
      }

      const userAnswer = matchedAnswer.userAnswer || "";
      const transformedUserAnswer = transformString(userAnswer);
      const transformedExactAnswer = transformString(question.answerValue);

      if (question.answers.length > 0) {
        const correctOption = question.answers.find(
          (item: any) => item.isCorrectAnswer
        );
        const transformedCorrectOption = transformString(
          correctOption.answerOption || ""
        );
        const transformedUserOption = transformString(
          matchedAnswer.userOption || ""
        );
        if (transformedUserOption === transformedCorrectOption) {
          return {
            ...question,
            isAttempted: true,
            isCorrect: true,
            userAnswer,
            userOption: matchedAnswer.userOption || "",
          };
        }
      } else if (transformedUserAnswer === transformedExactAnswer) {
        return {
          ...question,
          isAttempted: true,
          isCorrect: true,
          userAnswer,
          userOption: matchedAnswer.userOption || "",
        };
      }

      return {
        ...question,
        isAttempted: true,
        isCorrect: false,
        userAnswer,
        userOption: matchedAnswer.userOption || "",
      };
    });
  };

  const caluculateCorrectAnswers = (answers: any) => {
    return answers.filter((item: any) => item?.isCorrect);
  };
  const caluculateResultsTableData = (data: any) => {
    let result: any = [];

    const caluculateUserAnswer = (itm: any) => {
      if (itm?.answers.length > 0) {
        return itm?.userOption ? itm?.userOption : "Omitted";
      } else {
        return itm?.userAnswer ? itm?.userAnswer : "Omitted";
      }
    };
    const sectionName = (section: any) => {
      if (section === "ReadingAndWriting") {
        return "Reading and Writing";
      } else {
        return "Math";
      }
    };
    data.forEach((item: any) => {
      let content: any = {
        section: "",
        no: "",
        questionType: "",
        correctAnswer: "",
        yourChoice: "",
        explaination: "",
        isCorrect: false,
        _id: "",
      };
      content["section"] =
        sectionName(item?.sectionName) + " : " + item?.moduleName;
      content["no"] = item?.qIdUi;
      content["questionType"] =
        item.answers.length > 0 ? "Multiple Choice" : "Short Answer";
      content["correctAnswer"] = item?.exactAnswer ? item.exactAnswer : "";
      content["yourChoice"] = caluculateUserAnswer(item);
      content["explaination"] = item?.explaination ? item.explaination : "";
      content["isCorrect"] = item?.isCorrect || false;
      content["_id"] = item?.["_id"];
      result.push(content);
    });
    return result;
  };
  const hasCalculatedResults = useRef(false);

  const caluculateResults = async () => {
    setIsLoading(true);
    if (hasCalculatedResults.current) return;
    const readingModuleOneOverallResult = caluculateOverallResult(
      readingModuleOneQuestions,
      writingModuleOneAnswers
    );
    const readingModuleTwoOverallResult = caluculateOverallResult(
      readingModuleTwoQuestions,
      writingModuleTwoAnswers
    );
    const mathsModuleOneOverallResult = caluculateOverallResult(
      mathModuleOneQuestions,
      mathsModuleOneAnswers
    );
    const mathsModuleTwoOverallResult = caluculateOverallResult(
      mathModuleTwoQuestions,
      mathsModuleTwoAnswers
    );
    let combinedResults = [
      ...readingModuleOneOverallResult,
      ...readingModuleTwoOverallResult,
      ...mathsModuleOneOverallResult,
      ...mathsModuleTwoOverallResult,
    ];
    let resultsTableData = caluculateResultsTableData(combinedResults);
    let incorrectResultsTableData = resultsTableData?.filter(
      (item: any) => !item?.isCorrect
    );
    let readingModule1CorrectAnswers = caluculateCorrectAnswers(
      readingModuleOneOverallResult
    );
    let readingModule2CorrectAnswers = caluculateCorrectAnswers(
      readingModuleTwoOverallResult
    );
    let mathModule1CorrectAnswers = caluculateCorrectAnswers(
      mathsModuleOneOverallResult
    );
    let mathModule2CorrectAnswers = caluculateCorrectAnswers(
      mathsModuleTwoOverallResult
    );
    hasCalculatedResults.current = true;
    let totalscaledscore = 0;//if the end test fails so the scores in save test will be 0
    let readingandwritingscaledscore = 0;
    let mathematicsscaledscore = 0;
    try{
      const mathModuleScore = mathModule2CorrectAnswers?.length;
      const response = await endTest({userTestId,mathModuleScore}).unwrap();
      if(response.status === "success"){
        const results = response.data;
        totalscaledscore = results.totalScaledScore;
        readingandwritingscaledscore = results?.readingAndWritingModule1ScaledScore + results?.readingAndWritingModule2ScaledScore;
        mathematicsscaledscore = results?.mathematicsModule1ScaledScore + results?.mathematicsModule2ScaledScore;
      }
    }catch(error){
      console.error(error);
    }
    const updatedResults: any = {
      readingModuleOneOverallResult: readingModuleOneOverallResult,
      readingModuleTwoOverallResult: readingModuleTwoOverallResult,
      mathsModuleOneOverallResult: mathsModuleOneOverallResult,
      mathsModuleTwoOverallResult: mathsModuleTwoOverallResult,
      readingModuleOneCorrectAnswers: readingModule1CorrectAnswers,
      readingModuleTwoCorrectAnswers: readingModule2CorrectAnswers,
      mathsModuleOneCorrectAnswers: mathModule1CorrectAnswers,
      mathsModuleTwoCorrectAnswers: mathModule2CorrectAnswers,
      allQuestionsTableData: resultsTableData,
      inCorrectQuestionsTableData: incorrectResultsTableData,
      overallFinaleScaledScore: totalscaledscore,
      overallReadingScaledScore: readingandwritingscaledscore,
      overallMathScaledScore: mathematicsscaledscore,
    };
    let savePayload = {
      get_id: userTestId,
      testId: [testId]?.flat(),
      userTestId: userTestId,
      questionData: null,
      answerData: {
        Mathematics: {
          Module1: resultSlice.mathsModuleOneAnswers,
          Module2: resultSlice.mathsModuleTwoAnswers,
        },
        ReadingAndWriting: {
          Module1: resultSlice.writingModuleOneAnswers,
          Module2: resultSlice.writingModuleTwoAnswers,
        },
      },
      additionalProps: {
        currentModuleName: currentModuleName,
        count: count,
        currentTime: currentTime,
        overallresult: updatedResults,
        strikedAndMarkedData: startTestSlice.strikedOptionsData,
      },
      testCompleted: true,
    };
    try {
      await saveAndExitTest(savePayload).unwrap();
      await userProcessActivity(userTestId).unwrap();
      const timeoutId = setTimeout(() => {
        dispatch(setQid(""));
        setIsLoading(false);
        dispatch(changeCurrentModuleName("testCompleted"));
      }, 2000);

      return () => clearTimeout(timeoutId);
    } catch (err) {
        setIsLoading(false);
    }
    setOverallResultSlice(updatedResults);
    localStorage.setItem("overallResults", JSON.stringify(updatedResults));
  };

  useEffect(() => {
    if (getItem("testType") === "FULL") {
      caluculateResults();
    }
  }, []);

  return (
    <>
      <div className="flex items-center flex-col z-50 md:p-0 p-6">
        <p className="text-[40px] mb-6 mt-8 -z-10 font-bold text-center">
          Practice Test Completed
        </p>
        <p className="md:w-[640px] w-full text-center text-lg mb-12">
          All your work has been saved, and we're uploading it now. Do not
          refresh this page or quit the app.
        </p>

        <div
          className="flex flex-col items-center justify-center leading-6 p-6 w-full md:w-[630px] rounded-md text-sm"
          style={{ boxShadow: "0px 4px 4px 0px #00000040" }}
        >
          <img src={processing} alt="processing" />
          <p className="text-lg text-center">
            If this screen does not update in a few minutes.
          </p>
          <p className="text-lg mb-1">
            Click <span className="font-bold">Return To Home</span>
          </p>
          <div className="my-6">
            <AuthButton
              text="Return To Home"
              icon={<RxHome size={24} />}
              iconPosition="left"
              onClick={() => {
                dispatch(setIsTestStarted(false));
                dispatch(setIsTestInstructionsOpen(false));
              }}
              loader={false}
              disabled={isLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TestCompletetion;
