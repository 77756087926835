import { useCallback } from "react";
import { useUserTestActivityMutation } from "../../redux/API/StartTest/startTestApi";

export type ActivityData = {
  testId: string;
  startTime: Date;
  endTime: Date;
  module: 301 | 302;
  section: 701 | 702;
  fromQuestion: number;
  toQuestion: number;
  event: 501 | 502 | 503 | 504 | 505 | 506 | 507;
  questionId:string;
};
const useSendTestActivity = () => {
  const [userTestActivity] = useUserTestActivityMutation();
  const sendTestActivity = useCallback(
    async (
    activityData: ActivityData) => {
      const {
        testId,
        startTime,
        endTime,
        module,
        section,
        fromQuestion,
        toQuestion,
        event,
        questionId
      } = activityData;
      const payload = {
        userTestId: testId,
        startTime: startTime?.toISOString(),
        endTime: endTime?.toISOString(),
        module,
        section,
        event,
        fromQuestion: `q${fromQuestion}`,
        toQuestion: `q${toQuestion}`,
        questionId
      };

      try {
        await userTestActivity(payload);
      } catch (error) {
        console.error("Error sending data to the API:", error);
      }
    },
    []
  );

  return sendTestActivity;
};

export default useSendTestActivity;
