import { FetchBaseQueryArgs } from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { getItem } from "../../utils/token";
import { getApiUrlToHandleError } from "../../utils";

const customBaseQuery = (props: FetchBaseQueryArgs) => {
  const baseQuery = fetchBaseQuery({ ...props });
  const fn: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
  > = async (args, api, extraOptions) => {
    api.dispatch({
      type: "loader/setLoader",
      payload: { isLoading: true, isError: false, error: "" },
    });
    const result = (await baseQuery(args, api, extraOptions)) as any;
    if (result?.data) {
      api.dispatch({
        type: "loader/setLoader",
        payload: { isLoading: false, isError: false, error: "" },
      });
      api.dispatch({
        type: "loader/setErrorPage",
        payload: false,
      });
    }
    if (result.error) {
      let errorPageUrls = getApiUrlToHandleError();
      
      let apiUrl = result?.meta?.request?.url ?? '';
      let containsErrorPageUrl = Array.isArray(errorPageUrls) ?  errorPageUrls.some(errorUrl => apiUrl.includes(errorUrl)) : false;
      
      if (containsErrorPageUrl || result.error.status >= 500) {
        api.dispatch({
          type: "loader/setErrorPage",
          payload: true,
        });
      }else{
        api.dispatch({
          type: "loader/setErrorPage",
          payload: false,
        });
      }
      
      if (result.error.status !== 401) {
        api.dispatch({
          type: "loader/setLoader",
          payload: {
            isLoading: false,
            isError: true,
            error: `${result?.error?.status}:${result?.error?.data?.title}`,
          },
        });
      } else {
        api.dispatch({
          type: "loader/setLoader",
          payload: { isLoading: false, isError: true, error: `Un Authorised` },
        });
        const route = getItem("route");
        if (route) {
          const keyToKeep = "route";
          for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (key && key !== keyToKeep) {
              localStorage.removeItem(key);
            }
          }
        } else {
          localStorage.clear();
        }
      }
    }
    setTimeout(() => {}, 400);
    return result;
  };
  return fn;
};

export default customBaseQuery;
