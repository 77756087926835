import { useCallback, useEffect, useState } from "react";
import CustomButton from "../ReUsableComponents/CustomButton";
import CommonTestModuleLayout from "../ReUsableComponents/CommonTestModuleLayout";
import {
  changeCurrentModuleName,
  setCount,
  setIsModuleCompleted,
  setIsPopUp,
  setQuestionId,
  setReviewPageClicked,
  setSpentTime,
  setTestCompleted,
} from "../../redux/Slices/startFullTestSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectFullTestState } from "../../redux/Selectors/selectorsUse";
import { RootState } from "../../redux/Store/store";
import { getItem } from "../../utils/token";
import useSendTestActivity, { ActivityData } from "../customHook/useUserTestActivity";

const MathModuleTwo = () => {
  const dispatch = useDispatch();
  const { startTestSlice, resultSlice } = useSelector(selectFullTestState);
  const { count, mathModuleTwoQuestions, userTestId, reviewPageClicked } =
    startTestSlice;
  const saveHandler = () => {
    // dispatch(openDialog("mathsInstructions"));
  };
  const { currentTime, currentModuleName } = useSelector(
    (state: RootState) => state.root.startFullTest
  );
     const isPreview = getItem('testType') === 'PREVIEW';
     const [startTime, setStartTime] = useState<Date | null>(null);
     const [isNextDisabled, setIsNextDisabled] = useState(false);
     const sendTestActivity = useSendTestActivity();
     useEffect(() => {
       setStartTime(new Date());
     }, [count]);
  const heading = useCallback(() => {
    return (
      <div className="flex flex-col">
        <div className="text-base font-semibold">Section 2,Module 2: Math Questions</div>
      </div>
    );
  }, []);
  const timer = () => {
    return <div>9:25</div>;
  };
  const exit = () => {
    return (
      <div>
        <CustomButton
          name="Save & Exit"
          onClickHandler={saveHandler}
          bg="bg-white"
          color="[#333]"
          font="font-bold"
          width="w-full"
          border="border-[#333]"
          py="py-2"
          round="full"
        />
      </div>
    );
  };
  const children = () => {
    return (
      <div className="flex justify-center items-center">
        Maths Module TWO Children Component
      </div>
    );
  };
  const textName = () => {
    return <div className="font-semibold"> {getItem("currentTestName")} </div>;
  };
   let moduleCompleteTimeout: NodeJS.Timeout | null = null;
   let mathModuleTimeOut: NodeJS.Timeout | null = null;
  const nexxtClickHandler = () => {
     if (!startTime || isNextDisabled) return;
     setIsNextDisabled(true);
     const endTime = new Date();
    dispatch(setReviewPageClicked(false));
    if (count > mathModuleTwoQuestions.length - 1 || reviewPageClicked) {
      dispatch(
        setSpentTime({ field: "mathModuleTwoSpentTime", value: +currentTime })
      );
      dispatch(setIsModuleCompleted(true));
      dispatch(setTestCompleted(true));   
      moduleCompleteTimeout = setTimeout(() => {
        dispatch(changeCurrentModuleName("moduleComplete"));
      }, 500);
      mathModuleTimeOut = setTimeout(() => {
        dispatch(changeCurrentModuleName("testCompletion"));
      }, 7000);
      dispatch(setCount(""));
    } else {
      setStartTime(endTime);
      dispatch(setCount("Increment"));
      if(!isPreview){
      const activityData: ActivityData = {
        testId: userTestId,
        startTime,
        endTime,
        module: 302,
        section: 701,
        fromQuestion: count + 1,
        toQuestion:
          count + 1 === mathModuleTwoQuestions.length ? count + 1 : count + 2,
        event: 501,
        questionId: mathModuleTwoQuestions[count]._id,
      };
      dispatch(setQuestionId(mathModuleTwoQuestions[count]._id));
      sendTestActivity(activityData);
    }
    }
    dispatch(setIsPopUp(false));
    setTimeout(() => setIsNextDisabled(false), 500);
  };

   useEffect(() => {
     return () => {
       dispatch(setIsModuleCompleted(false));
       if (moduleCompleteTimeout) clearTimeout(moduleCompleteTimeout);
       if (mathModuleTimeOut) clearTimeout(mathModuleTimeOut);
     };
   }, []);

   useEffect(() => {
    if(currentTime <= 0 && getItem("testType") !== "PREVIEW"){
      dispatch(setIsModuleCompleted(true));
      dispatch(setTestCompleted(true));   
      moduleCompleteTimeout = setTimeout(() => {
        dispatch(changeCurrentModuleName("moduleComplete"));
      }, 500);
      mathModuleTimeOut = setTimeout(() => {
        dispatch(changeCurrentModuleName("testCompletion"));
      }, 7000);
      dispatch(setCount(""));
    }
   },[currentTime]);

  const next = () => {
    return (
      <div>
        <CustomButton
          name="Next"
          onClickHandler={nexxtClickHandler}
          font="font-bold transition duration-700 ease-in-out active:underline active:bg-secondaryColor active:opacity-50"
          width="text-sm"
          py="py-2 px-5"
          round="full"
          hover="hover:bg-primaryColor"
          disabled={isNextDisabled}
        />
      </div>
    );
  };
  useEffect(() => {
    dispatch(changeCurrentModuleName("mathModule2"));
    dispatch(setIsModuleCompleted(false));
  }, [dispatch]);
  
  useEffect(() => {
    if (currentTime <= 0) {
      dispatch(setIsModuleCompleted(true));
      moduleCompleteTimeout = setTimeout(() => {
        dispatch(changeCurrentModuleName("moduleComplete"));
      }, 500);
      mathModuleTimeOut = setTimeout(() => {
        dispatch(changeCurrentModuleName("testCompletion"));
      }, 7000);
    }
  }, [currentTime, dispatch]);
  return (
    <>
      {" "}
      <CommonTestModuleLayout
        heading={heading}
        timer={timer}
        exit={exit}
        textName={textName}
        questionsList={startTestSlice.mathModuleTwoQuestions}
        next={next}
        moduleName={"mathsModuleTwoAnswers"}
        userAnswers={resultSlice.mathsModuleTwoAnswers}
        module={302}
        section={701}
        setStartTime={setStartTime}
        startTime={startTime}
        headerHeading={"Section 2, Module 2: Math Questions"}
      >
        {children}
      </CommonTestModuleLayout>
    </>
  );
};

export default MathModuleTwo;
